import { render, staticRenderFns } from "./DemoFormLayoutCollapsible.vue?vue&type=template&id=5cef1f6e&scoped=true&"
import script from "./DemoFormLayoutCollapsible.vue?vue&type=script&lang=js&"
export * from "./DemoFormLayoutCollapsible.vue?vue&type=script&lang=js&"
import style0 from "./DemoFormLayoutCollapsible.vue?vue&type=style&index=0&id=5cef1f6e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5cef1f6e",
  null
  
)

export default component.exports