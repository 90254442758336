import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VForm,[_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"3"}},[_c('label',{attrs:{"for":"firstname"}},[_vm._v("First Name")])]),_c(VCol,{attrs:{"cols":"12","md":"9"}},[_c(VTextField,{attrs:{"id":"firstname","outlined":"","dense":"","placeholder":"First Name","hide-details":""},model:{value:(_vm.firstname),callback:function ($$v) {_vm.firstname=$$v},expression:"firstname"}})],1),_c(VCol,{attrs:{"cols":"12","md":"3"}},[_c('label',{attrs:{"for":"email"}},[_vm._v("Email")])]),_c(VCol,{attrs:{"cols":"12","md":"9"}},[_c(VTextField,{attrs:{"id":"email","outlined":"","dense":"","placeholder":"Email","hide-details":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1),_c(VCol,{attrs:{"cols":"12","md":"3"}},[_c('label',{attrs:{"for":"mobile"}},[_vm._v("Mobile")])]),_c(VCol,{attrs:{"cols":"12","md":"9"}},[_c(VTextField,{attrs:{"id":"mobile","type":"number","outlined":"","dense":"","placeholder":"Number","hide-details":""},model:{value:(_vm.mobile),callback:function ($$v) {_vm.mobile=$$v},expression:"mobile"}})],1),_c(VCol,{attrs:{"cols":"12","md":"3"}},[_c('label',{attrs:{"for":"password"}},[_vm._v("Password")])]),_c(VCol,{attrs:{"cols":"12","md":"9"}},[_c(VTextField,{attrs:{"id":"password","type":"password","outlined":"","dense":"","placeholder":"Password","hide-details":""},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1),_c(VCol,{attrs:{"offset-md":"3","cols":"12"}},[_c(VCheckbox,{staticClass:"mt-0",attrs:{"label":"Remember me","hide-details":""},model:{value:(_vm.checkbox),callback:function ($$v) {_vm.checkbox=$$v},expression:"checkbox"}})],1),_c(VCol,{attrs:{"offset-md":"3","cols":"12"}},[_c(VBtn,{attrs:{"color":"primary"}},[_vm._v(" Submit ")]),_c(VBtn,{staticClass:"mx-2",attrs:{"type":"reset","outlined":""}},[_vm._v(" Reset ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }